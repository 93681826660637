import React from 'react';
import ReactMarkdown from 'react-markdown';

import { Box, Typography } from '@mui/material';

import { ComponentStyleProps } from '../pages/types';
import withCommonStyles from './WithCommonStyles';

interface TextBoxProps extends ComponentStyleProps {
  texts: { type: 'title' | 'subtitle' | 'paragraph'; contents: string }[];
  sx: any;
}

const TextBox: React.FC<TextBoxProps> = ({ texts, sx }) => {
  return (
    <Box
      sx={{
        margin: '20px',
        py: '10px',
        marginTop: '30px',
        maxWidth: '1100px',
        width: '100%',
        ...sx,
      }}
    >
      <Box sx={{ maxWidth: '1100px' }}>
        {texts?.map((text, index) => {
          switch (text.type) {
            case 'title':
              return (
                <Typography variant="h3" key={index}>
                  <ReactMarkdown>{text.contents}</ReactMarkdown>
                </Typography>
              );
            case 'subtitle':
              return (
                <Typography variant="h4" key={index}>
                  <ReactMarkdown>{text.contents}</ReactMarkdown>
                </Typography>
              );
            case 'paragraph':
              return (
                <Typography variant="body1" key={index}>
                  <ReactMarkdown>{text.contents}</ReactMarkdown>
                </Typography>
              );
            default:
              return null;
          }
        })}
      </Box>
    </Box>
  );
};

export default withCommonStyles(TextBox);
