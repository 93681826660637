import React, { ReactNode, useState } from 'react';

import { ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material';
import { Box, IconButton, Modal, Typography } from '@mui/material';

import { ImageObject } from '../../pages/types';

interface ImageFullscreenProps {
  firstImage: ImageObject;
  images: ImageObject[];
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  children: ReactNode;
}

const ImageFullscreen: React.FC<ImageFullscreenProps> = ({
  images,
  currentIndex,
  setCurrentIndex,
  children,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePrev = (e: React.MouseEvent) => {
    const index = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    e.stopPropagation();
    setCurrentIndex(index);
  };

  const handleNext = (e: React.MouseEvent) => {
    const index = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    e.stopPropagation();
    setCurrentIndex(index);
  };

  return (
    <>
      <div onClick={handleOpen} style={{ cursor: 'pointer' }}>
        {children}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'relative',
          }}
          onClick={handleClose}
        >
          <IconButton
            sx={{
              position: 'absolute',
              left: '10px',
              color: 'white',
              fontSize: '3rem',
              opacity: 0.7,
            }}
            size="large"
            onClick={handlePrev}
          >
            <ArrowCircleLeft fontSize="inherit" />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              src={images[currentIndex].url}
              alt={images[currentIndex].altText}
              style={{
                maxWidth: '90%',
                maxHeight: '80vh',
                objectFit: 'contain',
              }}
            />
            {images[currentIndex].title && (
              <Typography
                variant="h6"
                sx={{
                  color: 'white',
                  marginTop: 2,
                  textAlign: 'center',
                }}
              >
                {images[currentIndex].title}
              </Typography>
            )}
          </Box>
          <Typography
            variant="body1"
            sx={{
              color: 'white',
              position: 'absolute',
              bottom: '10px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            {currentIndex + 1} / {images.length}
          </Typography>
          <IconButton
            sx={{
              position: 'absolute',
              right: '10px',
              color: 'white',
              fontSize: '3rem',
              opacity: 0.7,
            }}
            size="large"
            onClick={handleNext}
          >
            <ArrowCircleRight fontSize="inherit" />
          </IconButton>
        </Box>
      </Modal>
    </>
  );
};

export default ImageFullscreen;
