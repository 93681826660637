import React from 'react';
import { createHashRouter, RouterProvider } from 'react-router-dom';

import Footer from './components/footer';
import TopNavigation from './components/top-navigation';
import { useConfig } from './ConfigContext';
import AdminPage from './pages/admin';
import DynamicPage from './pages/dynamic-page';

export interface ComponentProps {
  [key: string]: any;
}

export interface PageConfig {
  menutitle: string;
  components: {
    type: string;
    props: ComponentProps;
  }[];
}
export interface PageData {
  menutitle: string;
  components: {
    type: string;
    props: ComponentProps;
  }[];
}

export interface FooterConfig {
  title?: string;
  subtitle?: string;
  links?: {
    instagram?: string;
    facebook?: string;
    twitter?: string;
    youtube?: string;
    linkedin?: string;
  };
}

export interface Config {
  theme: any;
  pages: Record<string, PageConfig>;
  footerConfig: FooterConfig;
}

function isPageConfig(obj: any): obj is PageConfig {
  return (
    obj &&
    typeof obj === 'object' &&
    'menutitle' in obj &&
    'components' in obj &&
    Array.isArray(obj.components)
  );
}

const App: React.FC = () => {
  const { config } = useConfig();
  console.warn('Porfolio Generator version 1.0');
  const validPages: Record<string, PageConfig> = config?.pages
    ? Object.entries(config.pages).reduce((acc, [pageName, pageConfig]) => {
        if (isPageConfig(pageConfig)) {
          acc[pageName] = pageConfig;
        } else {
          console.error(`Invalid page configuration for: ${pageName}`);
        }
        return acc;
      }, {} as Record<string, PageConfig>)
    : {};

  const router = createHashRouter([
    {
      path: '/admin',
      element: <AdminPage />,
    },
    {
      path: '/',
      element: (
        <>
          <TopNavigation />
          <DynamicPage pageName={'Home'} />
          <Footer />
        </>
      ),
    },
    ...Object.entries(validPages).map(([pageName]) => ({
      path: pageName === 'Home' ? '/' : `/${pageName}`,
      element: (
        <>
          <TopNavigation />
          <DynamicPage pageName={pageName} />
          <Footer />
        </>
      ),
    })),
  ]);

  return <RouterProvider router={router} />;
};

export default App;
