import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

import { useConfig } from '../ConfigContext';

const TopNavigation = () => {
  const theme = useTheme();
  const config = useConfig();
  const pages = config?.config?.pages;
  const location = useLocation();

  if (!pages) {
    return null;
  }

  const [mobileOpen, setMobileOpen] = useState(false);
  const [hideNav, setHideNav] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [selectedPage, setSelectedPage] = useState(location.pathname);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    setHideNav(prevScrollPos < currentScrollPos && currentScrollPos > 50);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    setSelectedPage(location.pathname);
    window.scrollTo(0, 0); // Scroll to top when the selected page changes
  }, [location.pathname]);

  const pageKeys = Object.keys(pages);

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: 'center', zIndex: 1000 }}
    >
      <List>
        {pageKeys
          .filter((pageKey) => pageKey !== pageKeys[0])
          .map((pageKey) => (
            <ListItem key={pageKey} component={Link} to={`/${pageKey}`}>
              <ListItemText
                primary={
                  <Typography variant="body1">
                    {pages[pageKey].menutitle}
                  </Typography>
                }
                sx={{
                  color: theme.palette.primary.main,
                  fontWeight:
                    selectedPage === `/${pageKey}` ? 'bold' : 'normal',
                  textDecoration:
                    selectedPage === `/${pageKey}` ? 'underline' : 'none',
                }}
              />
            </ListItem>
          ))}
      </List>
    </Box>
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: '100%',
        zIndex: 1000,
        position: 'fixed',
        pointerEvents: 'none',
        transition: 'top 0.3s',
        top: hideNav ? '-220px' : '0',
      }}
    >
      <AppBar
        position="sticky"
        className="app-bar"
        sx={{
          boxShadow: 'none',
          height: { xs: 70, sm: 80, md: 100 },
        }}
      >
        <Toolbar sx={{ pointerEvents: 'auto', verticalAlign: 'middle' }}>
          <Button
            variant="text"
            component={Link}
            to="/"
            sx={{
              fontWeight: selectedPage === '/' ? 'bold' : 'normal',
              textDecoration: selectedPage === '/' ? 'underline' : 'none',
            }}
          >
            {pages.Home?.menutitle || 'Home'}
          </Button>

          {/* Mobile Menu Button */}
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ ml: 'auto', display: { md: 'none' } }} // Show only on small screens
          >
            <MenuIcon />
          </IconButton>

          {/* Desktop Navigation */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', sm: 'none', md: 'flex' },
              justifyContent: 'flex-end',
              gap: '40px',
            }}
          >
            {pageKeys
              .filter(
                (pageKey) =>
                  pageKey !== 'Home' &&
                  pageKey !== pageKeys[pageKeys.length - 1]
              )
              .map((pageKey) => (
                <Button
                  key={pageKey}
                  variant="text"
                  component={Link}
                  to={`/${pageKey}`}
                  sx={{
                    fontWeight:
                      selectedPage === `/${pageKey}` ? 'bold' : 'normal',
                    textDecoration:
                      selectedPage === `/${pageKey}` ? 'underline' : 'none',
                  }}
                >
                  {pages[pageKey].menutitle}
                </Button>
              ))}
            {pageKeys.length > 1 && (
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={`/${pageKeys[pageKeys.length - 1]}`}
                sx={{
                  fontWeight:
                    selectedPage === `/${pageKeys[pageKeys.length - 1]}`
                      ? 'bold'
                      : 'normal',
                }}
              >
                {pages[pageKeys[pageKeys.length - 1]].menutitle}
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer anchor="right" open={mobileOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>
    </Box>
  );
};

export default TopNavigation;
