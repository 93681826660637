import React from 'react';

import { Box, Typography } from '@mui/material';

import { ComponentStyleProps } from '../../pages/types';
import withCommonStyles from '../WithCommoStyles';

interface PageHeaderProps extends ComponentStyleProps {
  title: string;
  subtitle: string;
  backgroundImage: string;
  height?: string;
  sx?: any;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  subtitle: paragraph,
  backgroundImage,
  height,
  sx,
}) => {
  if (backgroundImage) backgroundImage = backgroundImage.replace(/ /g, '%20');
  return (
    <Box
      sx={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: height ? height : '30vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
        ...sx,
        ...(backgroundImage && { backgroundImage: `url(${backgroundImage})` }),
      }}
    >
      <Typography variant="h2" sx={{ paddingBottom: 5 }}>
        {title}
      </Typography>
      <Typography variant="h5">{paragraph}</Typography>
    </Box>
  );
};
export default withCommonStyles(PageHeader);
