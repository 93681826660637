import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';

import { Config, FooterConfig, PageData } from '../App';
import { ThemeEditor } from '../components/admin/theme-editor';
import PageEditor from '../components/PageEditor';
import { handleUpload, useConfig } from '../ConfigContext';

const AdminPage = () => {
  const config = useConfig().config;
  const [configParam, setConfigParam] = useState<string | null>('anundi');

  if (!config) {
    return null;
  }
  const [currentConfig, setCurrentConfig] = useState<Config>(config);

  const [activePage, setActivePage] = useState<string>(
    Object.keys(currentConfig.pages)[0] || '' // Set initial active page safely
  );
  const [newPageName, setNewPageName] = useState('');
  const [isAddPageDialogOpen, setIsAddPageDialogOpen] = useState(false);
  const [isFooterDialogOpen, setIsFooterDialogOpen] = useState(false);

  const [isThemeDialogOpen, setIsThemeDialogOpen] = useState(false);

  const [isDirty, setIsDirty] = useState(false);

  const [isRenamePageDialogOpen, setIsRenamePageDialogOpen] = useState(false);
  const [newPageKey, setNewPageKey] = useState('');

  useEffect(() => {
    setIsDirty(true);
  }, [currentConfig]);

  const handlePageChange = (event: React.SyntheticEvent, newValue: string) => {
    setActivePage(newValue);
  };

  const handleSave = (updatedPageData: PageData) => {
    setCurrentConfig((prevConfig) => ({
      ...prevConfig,
      pages: {
        ...prevConfig.pages,
        [activePage]: updatedPageData,
      },
    }));
  };

  const handleConfigSave = () => {
    console.log('Saving config:', currentConfig);
    const blob = new Blob([JSON.stringify(currentConfig, null, 2)], {
      type: 'application/json',
    });
    const filename = `${configParam}.json`;
    handleUpload(blob, filename);
  };

  useEffect(() => {
    const hostname = window.location.hostname;
    let param = hostname ? hostname : null;
    console.log('using config name:', param);

    if (!param) {
      const urlParams = new URLSearchParams(window.location.search);
      param = urlParams.get('config');
    }

    if (!param) {
      param = 'anundi';
    }

    setConfigParam(param);
  }, []);

  const handleAddPage = () => {
    setCurrentConfig((prevConfig) => ({
      ...prevConfig,
      pages: {
        ...prevConfig.pages,
        [newPageName]: { menutitle: newPageName, components: [] },
      },
    }));
    setNewPageName('');
    setIsAddPageDialogOpen(false);
  };

  const handleDeletePage = () => {
    setCurrentConfig((prevConfig) => {
      const updatedPages = { ...prevConfig.pages };
      delete updatedPages[activePage];

      // Set active page to the first page in the updated list
      const newActivePage = Object.keys(updatedPages)[0] || '';

      return {
        ...prevConfig,
        pages: updatedPages,
        activePage: newActivePage,
      };
    });
  };

  const handleSaveFooter = (updatedFooterConfig: FooterConfig) => {
    setCurrentConfig((prevConfig) => ({
      ...prevConfig,
      footerConfig: updatedFooterConfig,
    }));
  };

  const handleThemeChange = (key: string, value: any) => {
    setCurrentConfig((prevConfig) => {
      const keys = key.split('.');
      const lastKey = keys.pop();
      const newTheme = { ...prevConfig.theme };

      let nestedConfig = newTheme;
      keys.forEach((k) => {
        nestedConfig[k] = { ...nestedConfig[k] };
        nestedConfig = nestedConfig[k];
      });

      if (lastKey) {
        nestedConfig[lastKey] = value;
      }

      return {
        ...prevConfig,
        theme: newTheme,
      };
    });
  };

  const handleRenamePage = () => {
    if (newPageKey && newPageKey !== activePage) {
      setCurrentConfig((prevConfig) => {
        const updatedPages = { ...prevConfig.pages };
        // Copy the content from the old key to the new key
        updatedPages[newPageKey] = updatedPages[activePage];
        // Delete the old key
        delete updatedPages[activePage];

        return {
          ...prevConfig,
          pages: updatedPages,
        };
      });

      // Update active page to the new name
      setActivePage(newPageKey);
      setIsRenamePageDialogOpen(false);
    }
  };

  const handleMoveTab = (pageKey: string, direction: 'up' | 'down') => {
    setCurrentConfig((prevConfig) => {
      // Get ordered list of page keys
      const pageKeys = Object.keys(prevConfig.pages);
      const currentIndex = pageKeys.indexOf(pageKey);

      // Calculate new index
      const newIndex =
        direction === 'up'
          ? Math.max(0, currentIndex - 1)
          : Math.min(pageKeys.length - 1, currentIndex + 1);

      // If position doesn't change, return unchanged config
      if (newIndex === currentIndex) return prevConfig;

      // Create new ordered array of keys by swapping positions
      const newPageKeys = [...pageKeys];
      [newPageKeys[currentIndex], newPageKeys[newIndex]] = [
        newPageKeys[newIndex],
        newPageKeys[currentIndex],
      ];

      // Create new pages object with the updated order
      const orderedPages: typeof prevConfig.pages = {};
      newPageKeys.forEach((key) => {
        orderedPages[key] = prevConfig.pages[key];
      });

      // Return updated config
      return {
        ...prevConfig,
        pages: orderedPages,
      };
    });
  };

  if (!configParam) {
    return <div>Config not found. You need to create subdomain.</div>;
  }

  return (
    <Box sx={{ paddingTop: '100px' }}>
      <Button variant="contained" color="primary" component={Link} to="/">
        Takaisin sivulle
      </Button>
      <Box sx={{ height: '300%' }}></Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box sx={{ width: '70%' }}>
          <Tabs
            value={activePage}
            onChange={handlePageChange}
            aria-label="page navigation tabs"
            variant="scrollable"
            scrollButtons="auto"
            textColor="secondary"
            indicatorColor="secondary"
          >
            {Object.keys(currentConfig.pages).map((pageKey) => (
              <Tab
                key={pageKey}
                value={pageKey}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', mr: 1 }}
                    >
                      <Button
                        size="small"
                        color="secondary"
                        sx={{ minWidth: 'auto', p: 0, minHeight: '12px' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMoveTab(pageKey, 'up');
                        }}
                      >
                        ◀
                      </Button>
                    </Box>
                    {pageKey}
                    {activePage === pageKey && (
                      <Button
                        size="small"
                        color="secondary"
                        sx={{ ml: 1, minWidth: 'auto', p: '2px' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setNewPageKey(pageKey);
                          setIsRenamePageDialogOpen(true);
                        }}
                      >
                        ✏️
                      </Button>
                    )}
                    <Button
                      size="small"
                      color="secondary"
                      sx={{
                        minWidth: 'auto',
                        p: 0,
                        minHeight: '12px',
                        paddingLeft: '5px',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMoveTab(pageKey, 'down');
                      }}
                    >
                      ▶
                    </Button>
                  </Box>
                }
                wrapped
                sx={{
                  color: 'black',
                  fontWeight: activePage === pageKey ? 'bold' : 'normal',
                }}
              />
            ))}
          </Tabs>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsAddPageDialogOpen(true)}
          >
            Add Page
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeletePage}
            disabled={Object.keys(currentConfig.pages).length <= 1}
          >
            Delete Page
          </Button>
          <Button
            sx={{
              position: 'fixed',
              bottom: '20px',
              right: '350px',
              zIndex: 1000,
            }}
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => setIsThemeDialogOpen(true)}
          >
            Edit Theme
          </Button>
          {/* Button to open Footer Edit Dialog */}
          <Button
            sx={{
              position: 'fixed',
              bottom: '20px',
              right: '150px',
              zIndex: 1000,
            }}
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => setIsFooterDialogOpen(true)}
          >
            Edit Footer
          </Button>
        </Box>
      </Box>
      <PageEditor
        pageData={
          currentConfig.pages[activePage] || { menutitle: '', components: [] }
        }
        onSave={handleSave}
        handleUpload={handleUpload}
        configParam={configParam}
      />
      {/*save button */}
      <Button
        sx={{ position: 'fixed', bottom: '20px', right: '20px' }}
        variant="contained"
        color="primary"
        onClick={handleConfigSave}
      >
        Save
      </Button>
      {/* Add page Edit Dialog */}
      <Dialog
        open={isAddPageDialogOpen}
        onClose={() => setIsAddPageDialogOpen(false)}
      >
        <DialogTitle>Add New Page</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Page Name"
            type="text"
            fullWidth
            value={newPageName}
            onChange={(e) => setNewPageName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddPageDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleAddPage}>
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Footer Edit Dialog */}
      <Dialog open={isFooterDialogOpen}>
        <DialogTitle>Edit Footer</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            value={currentConfig.footerConfig?.title || ''}
            onChange={(e) =>
              handleSaveFooter({
                ...currentConfig.footerConfig,
                title: e.target.value,
              })
            }
          />
          <TextField
            autoFocus
            margin="dense"
            label="Subtitle"
            type="text"
            fullWidth
            value={currentConfig.footerConfig?.subtitle || ''}
            onChange={(e) =>
              handleSaveFooter({
                ...currentConfig.footerConfig,
                subtitle: e.target.value,
              })
            }
          />
          <TextField
            autoFocus
            margin="dense"
            label="Instagram"
            type="text"
            fullWidth
            value={currentConfig.footerConfig?.links?.instagram || ''}
            onChange={(e) =>
              handleSaveFooter({
                ...currentConfig.footerConfig,
                links: {
                  ...currentConfig.footerConfig.links,
                  instagram: e.target.value,
                },
              })
            }
          />

          <TextField
            autoFocus
            margin="dense"
            label="Facebook"
            type="text"
            fullWidth
            value={currentConfig.footerConfig?.links?.facebook || ''}
            onChange={(e) =>
              handleSaveFooter({
                ...currentConfig.footerConfig,
                links: {
                  ...currentConfig.footerConfig.links,
                  facebook: e.target.value,
                },
              })
            }
          />

          <TextField
            autoFocus
            margin="dense"
            label="Youtube"
            type="text"
            fullWidth
            value={currentConfig.footerConfig?.links?.youtube || ''}
            onChange={(e) =>
              handleSaveFooter({
                ...currentConfig.footerConfig,
                links: {
                  ...currentConfig.footerConfig.links,
                  youtube: e.target.value,
                },
              })
            }
          />

          <TextField
            autoFocus
            margin="dense"
            label="LinkedIn"
            type="text"
            fullWidth
            value={currentConfig.footerConfig?.links?.linkedin || ''}
            onChange={(e) =>
              handleSaveFooter({
                ...currentConfig.footerConfig,
                links: {
                  ...currentConfig.footerConfig.links,
                  linkedin: e.target.value,
                },
              })
            }
          />

          <TextField
            autoFocus
            margin="dense"
            label="Instagram"
            type="text"
            fullWidth
            value={currentConfig.footerConfig?.links?.twitter || ''}
            onChange={(e) =>
              handleSaveFooter({
                ...currentConfig.footerConfig,
                links: {
                  ...currentConfig.footerConfig.links,
                  twitter: e.target.value,
                },
              })
            }
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setIsFooterDialogOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsFooterDialogOpen(false)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Theme Edit Dialog */}
      <Dialog
        open={isThemeDialogOpen}
        onClose={() => setIsThemeDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <ThemeEditor
          currentConfig={currentConfig}
          handleThemeChange={handleThemeChange}
          onClose={() => setIsThemeDialogOpen(false)}
        />
      </Dialog>

      {/* Rename Page Dialog */}
      <Dialog
        open={isRenamePageDialogOpen}
        onClose={() => setIsRenamePageDialogOpen(false)}
      >
        <DialogTitle>Rename Page</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Page Name"
            type="text"
            fullWidth
            value={newPageKey}
            onChange={(e) => setNewPageKey(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsRenamePageDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRenamePage}
            disabled={!newPageKey || newPageKey === activePage}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>

      <ReactRouterPrompt when={isDirty}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog open={isActive}>
            <div>
              <p>Do you really want to leave?</p>
              <button onClick={onCancel}>Cancel</button>
              <button onClick={onConfirm}>Ok</button>
            </div>
          </Dialog>
        )}
      </ReactRouterPrompt>
    </Box>
  );
};

export default AdminPage;
