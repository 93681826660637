import React, { useRef, useState } from 'react';

import { Box } from '@mui/material';

import { ComponentStyleProps, ImageObject } from '../../pages/types';
import withCommonStyles from '../WithCommoStyles';
import ImageFullscreen from './image-fullscreen';

interface ImageCarouselProps extends ComponentStyleProps {
  images: ImageObject[];
  height: string | number;
  sx: any;
}

const Carousel: React.FC<ImageCarouselProps> = ({ images, height, sx }) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (scrollRef.current) {
      setIsDragging(true);
      setStartX(e.pageX - scrollRef.current.offsetLeft);
      setScrollLeft(scrollRef.current.scrollLeft);
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !scrollRef.current) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust the scroll speed
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    if (scrollRef.current) {
      setIsDragging(true);
      setStartX(e.touches[0].pageX - scrollRef.current.offsetLeft);
      setScrollLeft(scrollRef.current.scrollLeft);
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDragging || !scrollRef.current) return;
    const x = e.touches[0].pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust the scroll speed
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  return (
    <>
      <Box
        sx={{
          py: '10px',
          position: 'relative',
          whiteSpace: 'nowrap',
          maxWidth: '100%',
          height: height,
          overflow: 'hidden',
          overflowX: 'scroll',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <Box
          ref={scrollRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onDragStart={(e) => e.preventDefault()} // Prevent default drag behavior
          sx={{
            height: height,
            width: '100%',
            display: 'flex',
            overflow: 'auto',
            whiteSpace: 'nowrap',
            scrollBehavior: 'smooth',
            '&::-webkit-scrollbar': { display: 'none' },
            cursor: isDragging ? 'grabbing' : 'grab', // Change cursor during dragging
          }}
        >
          {images.map((image, index) => (
            <ImageFullscreen
              firstImage={image}
              images={images}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              key={index}
            >
              <Box
                component="img"
                src={image.url}
                alt={image.altText || `Image ${index + 1}`}
                sx={{
                  flex: '0 0 auto',
                  width: 'auto',
                  height: '99%',
                  marginRight: '8px',
                  objectFit: 'cover',
                  cursor: 'pointer',
                }}
                onClick={() => setCurrentIndex(index)}
              />
            </ImageFullscreen>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default withCommonStyles(Carousel);
