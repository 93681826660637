import { useState } from 'react';
import { HexColorPicker } from 'react-colorful';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';

interface ThemeEditorProps {
  currentConfig: any;
  handleThemeChange: (path: string, value: any) => void;
  onClose: () => void;
}

export const ThemeEditor: React.FC<ThemeEditorProps> = ({
  currentConfig,
  handleThemeChange,
  onClose,
}) => {
  const [activeColorPicker, setActiveColorPicker] = useState<string | null>(
    null
  );
  return (
    <div>
      {/* Theme Edit Dialog */}

      <DialogTitle>Edit Theme</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Primary Colors Section */}
          <Box sx={{ border: '1px solid #e0e0e0', p: 2, borderRadius: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold" mb={1}>
              Primary Colors
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                gap: 2,
              }}
            >
              <Box>
                <TextField
                  label="Primary Main"
                  type="text"
                  fullWidth
                  value={
                    currentConfig.theme.palette?.primary?.main || '#1976d2'
                  }
                  onChange={(e) =>
                    handleThemeChange('palette.primary.main', e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          bgcolor:
                            currentConfig.theme.palette?.primary?.main ||
                            '#1976d2',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                        }}
                        onClick={() => setActiveColorPicker('primary.main')}
                      />
                    ),
                  }}
                />
                {activeColorPicker === 'primary.main' && (
                  <Box sx={{ mt: 1, mb: 2 }}>
                    <HexColorPicker
                      color={
                        currentConfig.theme.palette?.primary?.main || '#1976d2'
                      }
                      onChange={(color) =>
                        handleThemeChange('palette.primary.main', color)
                      }
                    />
                    <Button
                      size="small"
                      onClick={() => setActiveColorPicker(null)}
                      sx={{ mt: 1 }}
                    >
                      Close Picker
                    </Button>
                  </Box>
                )}
              </Box>

              <Box>
                <TextField
                  label="Primary Light"
                  type="text"
                  fullWidth
                  value={
                    currentConfig.theme.palette?.primary?.light || '#42a5f5'
                  }
                  onChange={(e) =>
                    handleThemeChange('palette.primary.light', e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          bgcolor:
                            currentConfig.theme.palette?.primary?.light ||
                            '#42a5f5',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                        }}
                        onClick={() => setActiveColorPicker('primary.light')}
                      />
                    ),
                  }}
                />
                {activeColorPicker === 'primary.light' && (
                  <Box sx={{ mt: 1, mb: 2 }}>
                    <HexColorPicker
                      color={
                        currentConfig.theme.palette?.primary?.light || '#42a5f5'
                      }
                      onChange={(color) =>
                        handleThemeChange('palette.primary.light', color)
                      }
                    />
                    <Button
                      size="small"
                      onClick={() => setActiveColorPicker(null)}
                      sx={{ mt: 1 }}
                    >
                      Close Picker
                    </Button>
                  </Box>
                )}
              </Box>

              <Box>
                <TextField
                  label="Primary Dark"
                  type="text"
                  fullWidth
                  value={
                    currentConfig.theme.palette?.primary?.dark || '#1565c0'
                  }
                  onChange={(e) =>
                    handleThemeChange('palette.primary.dark', e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          bgcolor:
                            currentConfig.theme.palette?.primary?.dark ||
                            '#1565c0',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                        }}
                        onClick={() => setActiveColorPicker('primary.dark')}
                      />
                    ),
                  }}
                />
                {activeColorPicker === 'primary.dark' && (
                  <Box sx={{ mt: 1, mb: 2 }}>
                    <HexColorPicker
                      color={
                        currentConfig.theme.palette?.primary?.dark || '#1565c0'
                      }
                      onChange={(color) =>
                        handleThemeChange('palette.primary.dark', color)
                      }
                    />
                    <Button
                      size="small"
                      onClick={() => setActiveColorPicker(null)}
                      sx={{ mt: 1 }}
                    >
                      Close Picker
                    </Button>
                  </Box>
                )}
              </Box>

              <Box>
                <TextField
                  label="Primary Contrast Text"
                  type="text"
                  fullWidth
                  value={
                    currentConfig.theme.palette?.primary?.contrastText ||
                    '#ffffff'
                  }
                  onChange={(e) =>
                    handleThemeChange(
                      'palette.primary.contrastText',
                      e.target.value
                    )
                  }
                  InputProps={{
                    endAdornment: (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          bgcolor:
                            currentConfig.theme.palette?.primary
                              ?.contrastText || '#ffffff',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                        }}
                        onClick={() =>
                          setActiveColorPicker('primary.contrastText')
                        }
                      />
                    ),
                  }}
                />
                {activeColorPicker === 'primary.contrastText' && (
                  <Box sx={{ mt: 1, mb: 2 }}>
                    <HexColorPicker
                      color={
                        currentConfig.theme.palette?.primary?.contrastText ||
                        '#ffffff'
                      }
                      onChange={(color) =>
                        handleThemeChange('palette.primary.contrastText', color)
                      }
                    />
                    <Button
                      size="small"
                      onClick={() => setActiveColorPicker(null)}
                      sx={{ mt: 1 }}
                    >
                      Close Picker
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          {/* Secondary Colors Section */}
          <Box sx={{ border: '1px solid #e0e0e0', p: 2, borderRadius: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold" mb={1}>
              Secondary Colors
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                gap: 2,
              }}
            >
              <Box>
                <TextField
                  label="Secondary Main"
                  type="text"
                  fullWidth
                  value={
                    currentConfig.theme.palette?.secondary?.main || '#9c27b0'
                  }
                  onChange={(e) =>
                    handleThemeChange('palette.secondary.main', e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          bgcolor:
                            currentConfig.theme.palette?.secondary?.main ||
                            '#9c27b0',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                        }}
                        onClick={() => setActiveColorPicker('secondary.main')}
                      />
                    ),
                  }}
                />
                {activeColorPicker === 'secondary.main' && (
                  <Box sx={{ mt: 1, mb: 2 }}>
                    <HexColorPicker
                      color={
                        currentConfig.theme.palette?.secondary?.main ||
                        '#9c27b0'
                      }
                      onChange={(color) =>
                        handleThemeChange('palette.secondary.main', color)
                      }
                    />
                    <Button
                      size="small"
                      onClick={() => setActiveColorPicker(null)}
                      sx={{ mt: 1 }}
                    >
                      Close Picker
                    </Button>
                  </Box>
                )}
              </Box>

              <Box>
                <TextField
                  label="Secondary Light"
                  type="text"
                  fullWidth
                  value={
                    currentConfig.theme.palette?.secondary?.light || '#ba68c8'
                  }
                  onChange={(e) =>
                    handleThemeChange('palette.secondary.light', e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          bgcolor:
                            currentConfig.theme.palette?.secondary?.light ||
                            '#ba68c8',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                        }}
                        onClick={() => setActiveColorPicker('secondary.light')}
                      />
                    ),
                  }}
                />
                {activeColorPicker === 'secondary.light' && (
                  <Box sx={{ mt: 1, mb: 2 }}>
                    <HexColorPicker
                      color={
                        currentConfig.theme.palette?.secondary?.light ||
                        '#ba68c8'
                      }
                      onChange={(color) =>
                        handleThemeChange('palette.secondary.light', color)
                      }
                    />
                    <Button
                      size="small"
                      onClick={() => setActiveColorPicker(null)}
                      sx={{ mt: 1 }}
                    >
                      Close Picker
                    </Button>
                  </Box>
                )}
              </Box>

              <Box>
                <TextField
                  label="Secondary Dark"
                  type="text"
                  fullWidth
                  value={
                    currentConfig.theme.palette?.secondary?.dark || '#7b1fa2'
                  }
                  onChange={(e) =>
                    handleThemeChange('palette.secondary.dark', e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          bgcolor:
                            currentConfig.theme.palette?.secondary?.dark ||
                            '#7b1fa2',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                        }}
                        onClick={() => setActiveColorPicker('secondary.dark')}
                      />
                    ),
                  }}
                />
                {activeColorPicker === 'secondary.dark' && (
                  <Box sx={{ mt: 1, mb: 2 }}>
                    <HexColorPicker
                      color={
                        currentConfig.theme.palette?.secondary?.dark ||
                        '#7b1fa2'
                      }
                      onChange={(color) =>
                        handleThemeChange('palette.secondary.dark', color)
                      }
                    />
                    <Button
                      size="small"
                      onClick={() => setActiveColorPicker(null)}
                      sx={{ mt: 1 }}
                    >
                      Close Picker
                    </Button>
                  </Box>
                )}
              </Box>

              <Box>
                <TextField
                  label="Secondary Contrast Text"
                  type="text"
                  fullWidth
                  value={
                    currentConfig.theme.palette?.secondary?.contrastText ||
                    '#ffffff'
                  }
                  onChange={(e) =>
                    handleThemeChange(
                      'palette.secondary.contrastText',
                      e.target.value
                    )
                  }
                  InputProps={{
                    endAdornment: (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          bgcolor:
                            currentConfig.theme.palette?.secondary
                              ?.contrastText || '#ffffff',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                        }}
                        onClick={() =>
                          setActiveColorPicker('secondary.contrastText')
                        }
                      />
                    ),
                  }}
                />
                {activeColorPicker === 'secondary.contrastText' && (
                  <Box sx={{ mt: 1, mb: 2 }}>
                    <HexColorPicker
                      color={
                        currentConfig.theme.palette?.secondary?.contrastText ||
                        '#ffffff'
                      }
                      onChange={(color) =>
                        handleThemeChange(
                          'palette.secondary.contrastText',
                          color
                        )
                      }
                    />
                    <Button
                      size="small"
                      onClick={() => setActiveColorPicker(null)}
                      sx={{ mt: 1 }}
                    >
                      Close Picker
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          {/* Text Colors Section */}
          <Box sx={{ border: '1px solid #e0e0e0', p: 2, borderRadius: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold" mb={1}>
              Text Colors
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                gap: 2,
              }}
            >
              <Box>
                <TextField
                  label="Text Primary"
                  type="text"
                  fullWidth
                  value={
                    currentConfig.theme.palette?.text?.primary ||
                    'rgba(0, 0, 0, 0.87)'
                  }
                  onChange={(e) =>
                    handleThemeChange('palette.text.primary', e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          bgcolor:
                            currentConfig.theme.palette?.text?.primary ||
                            'rgba(0, 0, 0, 0.87)',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                        }}
                        onClick={() => setActiveColorPicker('text.primary')}
                      />
                    ),
                  }}
                />
                {activeColorPicker === 'text.primary' && (
                  <Box sx={{ mt: 1, mb: 2 }}>
                    <HexColorPicker
                      color={
                        currentConfig.theme.palette?.text?.primary || '#000000'
                      }
                      onChange={(color) =>
                        handleThemeChange('palette.text.primary', color)
                      }
                    />
                    <Button
                      size="small"
                      onClick={() => setActiveColorPicker(null)}
                      sx={{ mt: 1 }}
                    >
                      Close Picker
                    </Button>
                  </Box>
                )}
              </Box>

              <Box>
                <TextField
                  label="Text Secondary"
                  type="text"
                  fullWidth
                  value={
                    currentConfig.theme.palette?.text?.secondary ||
                    'rgba(0, 0, 0, 0.6)'
                  }
                  onChange={(e) =>
                    handleThemeChange('palette.text.secondary', e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          bgcolor:
                            currentConfig.theme.palette?.text?.secondary ||
                            'rgba(0, 0, 0, 0.6)',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                        }}
                        onClick={() => setActiveColorPicker('text.secondary')}
                      />
                    ),
                  }}
                />
                {activeColorPicker === 'text.secondary' && (
                  <Box sx={{ mt: 1, mb: 2 }}>
                    <HexColorPicker
                      color={
                        currentConfig.theme.palette?.text?.secondary ||
                        '#000000'
                      }
                      onChange={(color) =>
                        handleThemeChange('palette.text.secondary', color)
                      }
                    />
                    <Button
                      size="small"
                      onClick={() => setActiveColorPicker(null)}
                      sx={{ mt: 1 }}
                    >
                      Close Picker
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          {/* Background Colors Section */}
          <Box sx={{ border: '1px solid #e0e0e0', p: 2, borderRadius: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold" mb={1}>
              Background Colors
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                gap: 2,
              }}
            >
              <Box>
                <TextField
                  label="Background Default"
                  type="text"
                  fullWidth
                  value={
                    currentConfig.theme.palette?.background?.default ||
                    '#ffffff'
                  }
                  onChange={(e) =>
                    handleThemeChange(
                      'palette.background.default',
                      e.target.value
                    )
                  }
                  InputProps={{
                    endAdornment: (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          bgcolor:
                            currentConfig.theme.palette?.background?.default ||
                            '#ffffff',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                        }}
                        onClick={() =>
                          setActiveColorPicker('background.default')
                        }
                      />
                    ),
                  }}
                />
                {activeColorPicker === 'background.default' && (
                  <Box sx={{ mt: 1, mb: 2 }}>
                    <HexColorPicker
                      color={
                        currentConfig.theme.palette?.background?.default ||
                        '#ffffff'
                      }
                      onChange={(color) =>
                        handleThemeChange('palette.background.default', color)
                      }
                    />
                    <Button
                      size="small"
                      onClick={() => setActiveColorPicker(null)}
                      sx={{ mt: 1 }}
                    >
                      Close Picker
                    </Button>
                  </Box>
                )}
              </Box>

              <Box>
                <TextField
                  label="Background Paper"
                  type="text"
                  fullWidth
                  value={
                    currentConfig.theme.palette?.background?.paper || '#ffffff'
                  }
                  onChange={(e) =>
                    handleThemeChange(
                      'palette.background.paper',
                      e.target.value
                    )
                  }
                  InputProps={{
                    endAdornment: (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          bgcolor:
                            currentConfig.theme.palette?.background?.paper ||
                            '#ffffff',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                        }}
                        onClick={() => setActiveColorPicker('background.paper')}
                      />
                    ),
                  }}
                />
                {activeColorPicker === 'background.paper' && (
                  <Box sx={{ mt: 1, mb: 2 }}>
                    <HexColorPicker
                      color={
                        currentConfig.theme.palette?.background?.paper ||
                        '#ffffff'
                      }
                      onChange={(color) =>
                        handleThemeChange('palette.background.paper', color)
                      }
                    />
                    <Button
                      size="small"
                      onClick={() => setActiveColorPicker(null)}
                      sx={{ mt: 1 }}
                    >
                      Close Picker
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          {/* Navigation Settings */}
          <Box sx={{ border: '1px solid #e0e0e0', p: 2, borderRadius: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold" mb={1}>
              Navigation
            </Typography>
            <TextField
              margin="dense"
              label="Navigation background"
              type="text"
              fullWidth
              value={
                currentConfig.theme.components?.MuiAppBar
                  ? currentConfig.theme.components.MuiAppBar?.styleOverrides
                      ?.root?.background
                  : ''
              }
              onChange={(e) =>
                handleThemeChange(
                  'components.MuiAppBar.styleOverrides.root.background',
                  e.target.value
                )
              }
              InputProps={{
                endAdornment: (
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      bgcolor:
                        currentConfig.theme.components?.MuiAppBar
                          ?.styleOverrides?.root?.background || 'transparent',
                      cursor: 'pointer',
                      border: '1px solid #ccc',
                    }}
                    onClick={() => setActiveColorPicker('nav.background')}
                  />
                ),
              }}
            />
            {activeColorPicker === 'nav.background' && (
              <Box sx={{ mt: 1, mb: 2 }}>
                <HexColorPicker
                  color={
                    currentConfig.theme.components?.MuiAppBar?.styleOverrides
                      ?.root?.background || '#ffffff'
                  }
                  onChange={(color) =>
                    handleThemeChange(
                      'components.MuiAppBar.styleOverrides.root.background',
                      color
                    )
                  }
                />
                <Button
                  size="small"
                  onClick={() => setActiveColorPicker(null)}
                  sx={{ mt: 1 }}
                >
                  Close Picker
                </Button>
              </Box>
            )}
          </Box>

          {/* Typography Settings */}
          <Box sx={{ border: '1px solid #e0e0e0', p: 2, borderRadius: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold" mb={1}>
              Typography
            </Typography>
            <TextField
              margin="dense"
              label="Font Family"
              type="text"
              fullWidth
              value={
                currentConfig.theme.typography?.fontFamily ||
                'Roboto, "Helvetica Neue", Arial, sans-serif'
              }
              onChange={(e) =>
                handleThemeChange('typography.fontFamily', e.target.value)
              }
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={onClose}>
          Save
        </Button>
      </DialogActions>
    </div>
  );
};
