import React, { useState } from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';

import { ComponentProps, PageData } from '../App';
import { availableComponents } from '../pages/dynamic-page';
import ComponentEditor from './ComponentEditor';

const PageEditor: React.FC<{
  pageData: PageData;
  onSave: (updatedPageData: PageData) => void;
  configParam: string;
  handleUpload: (blob: Blob, filename: string) => void;
}> = ({ pageData, onSave, configParam, handleUpload }) => {
  const [activeComponentIndex, setActiveComponentIndex] = useState(0);

  const handleComponentChange = (
    index: number,
    updatedProps: ComponentProps
  ) => {
    const updatedComponents = [...pageData.components];
    updatedComponents[index].props = updatedProps;
    onSave({ ...pageData, components: updatedComponents });
  };

  const handleAddComponent = (type: string) => {
    const newComponent: any = {
      type: type,
      props: {},
    };
    onSave({
      ...pageData,
      components: [...pageData.components, newComponent],
    });
  };

  const handleDeleteComponent = (index: number) => {
    const updatedComponents = pageData.components.filter(
      (_: any, i: any) => i !== index
    );
    onSave({ ...pageData, components: updatedComponents });
  };

  const handleMoveComponent = (index: number, direction: 'up' | 'down') => {
    const updatedComponents = [...pageData.components];
    const targetIndex = direction === 'up' ? index - 1 : index + 1;

    if (targetIndex >= 0 && targetIndex < updatedComponents.length) {
      const [movedComponent] = updatedComponents.splice(index, 1);
      updatedComponents.splice(targetIndex, 0, movedComponent);
      onSave({ ...pageData, components: updatedComponents });
    }
  };

  return (
    <Box
      sx={{ marginBottom: 10, maxWidth: 1100, textAlign: 'center', mx: 'auto' }}
    >
      <TextField
        label="Menu Title"
        value={pageData.menutitle}
        onChange={(e) => onSave({ ...pageData, menutitle: e.target.value })}
        fullWidth
        margin="normal"
      />
      <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
        {pageData.components.map((component: ComponentProps, index: number) => (
          <li key={index}>
            <Box border={1} p={2} mb={2}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6">
                  {index + 1}: {component.type}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label="move up"
                    onClick={() => handleMoveComponent(index, 'up')}
                    disabled={index === 0}
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                  <IconButton
                    aria-label="move down"
                    onClick={() => handleMoveComponent(index, 'down')}
                    disabled={index === pageData.components.length - 1}
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteComponent(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
              <ComponentEditor
                type={component.type}
                props={component.props}
                onChange={(updatedProps) =>
                  handleComponentChange(index, updatedProps)
                }
                handleUpload={handleUpload}
                configParam={configParam}
              />
            </Box>
          </li>
        ))}
      </ul>
      <Box>
        {availableComponents.map((component) => (
          <Button
            key={component.type}
            variant="contained"
            onClick={() => handleAddComponent(component.type)}
          >
            + {component.type}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default PageEditor;
