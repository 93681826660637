import { createTheme } from '@mui/material';

const defaultTheme = createTheme({
  palette: {},

  typography: {
    h1: {
      fontSize: '90px',
      fontWeight: 'bold',
      '@media (max-width: 768px)': {
        fontSize: '12vw',
      },
    },
    h2: {
      fontSize: '80px',
      fontWeight: 'bold',
      '@media (max-width: 768px)': {
        fontSize: '10vw',
      },
    },

    h3: {
      fontWeight: 'bold',
      fontSize: '36px',
      '@media (max-width: 768px)': {
        //mobile
        fontSize: '26px',
      },
    },
    h4: {
      fontWeight: 'regular',
      fontSize: '24px',
      '@media (max-width: 768px)': {
        //mobile
        fontSize: '16px',
      },
    },
    h5: {
      fontSize: '28px',
      fontWeight: '200',
      '@media (max-width: 768px)': {
        //mobile
        fontSize: '3.8vw',
      },
    },
    body1: {
      fontSize: '0.9rem',
      '@media (min-width:600px)': {
        fontSize: '1.1rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.1rem',
      },
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '0px !important',
          justifyContent: 'right',
          fontFamily: 'Sculpin',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // Applies to all button variants
          textTransform: 'none', // Common customization: remove uppercase
        },
        contained: {
          height: '48px', // Example: adjust height
          borderRadius: '30px', // Example: rounded corners
          padding: '10px 50px', // Example: adjust padding
          fontSize: '18px', // Example: adjust font size
        },
        text: {
          fontSize: '18px', // Example: adjust font size
          padding: '0px',
          minWidth: '0px',
        },
        // Add styles for other button variants (outlined, text) if needed
      },
    },
  },
});

export default defaultTheme;
