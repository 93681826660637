// DynamicPage.tsx

import React from 'react';

import { Box } from '@mui/material';

import Ingress from '../components/ingress';
import Carousel from '../components/photo-components/carousel';
import Hero from '../components/photo-components/hero';
import ImageGallery from '../components/photo-components/image-gallery';
import PageHeader from '../components/photo-components/page-header';
import TextBox from '../components/text-box';
import TitleParagraph from '../components/title-paragraph';
import { useConfig } from '../ConfigContext';

// Define the component type
interface PageComponent {
  type: string;
  component: React.FC<any>; // Use a more specific type for props if possible
}

// Create the public exported array of components
export const availableComponents: PageComponent[] = [
  { type: 'Hero', component: Hero },
  { type: 'PageHeader', component: PageHeader },
  { type: 'Ingress', component: Ingress },
  { type: 'TitleParagraph', component: TitleParagraph },
  { type: 'Carousel', component: Carousel },
  { type: 'TextBox', component: TextBox },
  { type: 'ImageGallery', component: ImageGallery },
];
interface DynamicPageProps {
  pageName: string;
}

const DynamicPage: React.FC<DynamicPageProps> = ({ pageName }) => {
  const { config } = useConfig();
  const pageConfig = config?.pages[pageName];
  if (!pageConfig) {
    return <div>Page not found!</div>;
  }

  return (
    <Box
      sx={{
        overflow: 'hidden',
        boxSizing: 'border-box',
        margin: '0 auto',
        textAlign: 'center',
        justifyContent: 'center',
      }}
    >
      {pageConfig.components.map((componentData, index) => {
        const component = availableComponents.find(
          (c) => c.type === componentData.type
        );

        if (component) {
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              {React.createElement(component.component, {
                ...componentData.props,
              })}
            </Box>
          );
        } else {
          return null;
        }
      })}
    </Box>
  );
};

export default DynamicPage;
