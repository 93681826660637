import React from 'react';

import { LinkedIn } from '@mui/icons-material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Box, IconButton, Typography } from '@mui/material';

import { useConfig } from '../ConfigContext';

const Footer: React.FC = () => {
  const { config } = useConfig();
  if (!config) {
    return null;
  }

  const { title, subtitle, links } = config.footerConfig;
  const theme = config.theme;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        padding: '100px 70px',
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
      }}
    >
      {/* Conditionally render title */}
      {title && <Typography variant="h4">{title}</Typography>}

      {/* Conditionally render subtitle */}
      {subtitle && <Typography variant="h5">{subtitle}</Typography>}

      {/* Conditionally render links section */}
      {links && (
        <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
          {links.instagram && (
            <IconButton
              href={links.instagram}
              target="_blank"
              aria-label="Instagram"
              sx={{ color: theme.palette.secondary.contrastText }}
            >
              <InstagramIcon />
            </IconButton>
          )}
          {links.facebook && (
            <IconButton
              href={links.facebook}
              target="_blank"
              aria-label="Facebook"
              sx={{ color: theme.palette.secondary.contrastText }}
            >
              <FacebookIcon />
            </IconButton>
          )}
          {links.twitter && (
            <IconButton
              href={links.twitter}
              target="_blank"
              aria-label="Twitter"
              sx={{ color: theme.palette.secondary.contrastText }}
            >
              <TwitterIcon />
            </IconButton>
          )}
          {links.youtube && (
            <IconButton
              href={links.youtube}
              target="_blank"
              aria-label="YouTube"
              sx={{ color: theme.palette.secondary.contrastText }}
            >
              <YouTubeIcon />
            </IconButton>
          )}
          {links.linkedin && (
            <IconButton
              href={links.linkedin}
              target="_blank"
              aria-label="LinkedIn"
              sx={{ color: theme.palette.secondary.contrastText }}
            >
              <LinkedIn />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Footer;
