import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';

import { AddCircleOutline, DeleteOutline } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { ComponentProps } from '../App';
import { ComponentStyleProps, ImageObject } from '../pages/types';
import {
  validateBorderRadius,
  validateHeight,
  validateSpacing,
  validateWidth,
} from './admin-utils';
import FileSelectorModal from './FileSelectorModal';

interface GeneralOptionsProps {
  props: ComponentProps & ComponentStyleProps;
  handleChange: (key: string, value: any) => void;
  open?: boolean;
  configParam: string;
  handleUpload: (blob: Blob, filename: string) => void;
  onBackgroundImageSelect?: (fileUrl: string) => void;
}

const GeneralOptions: React.FC<GeneralOptionsProps> = ({
  props,
  handleChange,
  open = false,
  configParam,
  handleUpload,
  onBackgroundImageSelect,
}) => {
  const [showOptions, setShowOptions] = useState(open);
  const [isFileSelectorOpen, setIsFileSelectorOpen] = useState(false);

  const handleFileSelect = (fileUrl: string) => {
    // Convert string to ImageObject when selecting background image
    const imageObject = {
      url: fileUrl,
      title: 'Background Image',
      altText: 'Background image for this component',
    };
    handleChange('backgroundImage', imageObject);
    setIsFileSelectorOpen(false);
    if (onBackgroundImageSelect) {
      onBackgroundImageSelect(fileUrl);
    }
  };

  // Helper function to get actual image URL from possibly string or ImageObject
  const getImageUrl = (image: string | ImageObject | undefined): string => {
    if (!image) return '';
    if (typeof image === 'string') return image;
    return image.url;
  };

  return (
    <>
      <Divider sx={{ my: 2 }} />
      <Typography variant="h6">General Options</Typography>
      <IconButton onClick={() => setShowOptions(!showOptions)}>
        {showOptions ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>

      {showOptions && (
        <Box display="flex" flexDirection="column" gap={2} mt={2} width="100%">
          {/* BACKGROUND SECTION */}
          <Box
            sx={{
              border: '1px solid #e0e0e0',
              borderRadius: 1,
              p: 2,
              mb: 2,
            }}
          >
            <Typography variant="subtitle1" fontWeight="bold" mb={1}>
              Background
            </Typography>
            <Box display="flex" flexWrap="wrap" alignItems="flex-start" gap={2}>
              {/* Background Image Column */}
              <Box flex="0 0 auto">
                {props.backgroundImage ? (
                  <>
                    <img
                      src={getImageUrl(props.backgroundImage)}
                      alt={
                        typeof props.backgroundImage === 'object'
                          ? props.backgroundImage.altText
                          : 'background'
                      }
                      style={{
                        width: '150px',
                        height: 'auto',
                        marginBottom: '8px',
                      }}
                    />
                    <Box display="flex" gap={1}>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => handleChange('backgroundImage', '')}
                        startIcon={<DeleteOutline />}
                      >
                        Remove Background Image
                      </Button>
                    </Box>

                    {/* Show title and alt text fields only if backgroundImage is an object */}
                    {typeof props.backgroundImage === 'object' && (
                      <Box sx={{ mt: 2 }}>
                        <TextField
                          label="Image Title"
                          value={props.backgroundImage.title || ''}
                          onChange={(e) =>
                            handleChange('backgroundImage', {
                              ...props.backgroundImage,
                              title: e.target.value,
                            })
                          }
                          fullWidth
                          margin="dense"
                          size="small"
                        />
                        <TextField
                          label="Alt Text"
                          value={props.backgroundImage.altText || ''}
                          onChange={(e) =>
                            handleChange('backgroundImage', {
                              ...props.backgroundImage,
                              altText: e.target.value,
                            })
                          }
                          fullWidth
                          margin="dense"
                          size="small"
                        />
                      </Box>
                    )}
                  </>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => setIsFileSelectorOpen(true)}
                    startIcon={<AddCircleOutline />}
                  >
                    Select Background Image
                  </Button>
                )}
              </Box>
              {/* Background Color Column */}
              <Box flex="1 0 250px">
                <TextField
                  label="Background Color"
                  value={props.background || ''}
                  onChange={(e) => handleChange('background', e.target.value)}
                  margin="dense"
                  size="small"
                  fullWidth
                />
                <HexColorPicker
                  color={props.background || '#000000'}
                  onChange={(color) => handleChange('background', color)}
                  style={{ marginTop: '8px', width: '100%', maxWidth: '250px' }}
                />
              </Box>
            </Box>
          </Box>

          {/* The rest of the general options remains unchanged */}
          {/* TEXT SECTION */}
          <Box
            sx={{
              border: '1px solid #e0e0e0',
              borderRadius: 1,
              p: 2,
              mb: 2,
            }}
          >
            <Typography variant="subtitle1" fontWeight="bold" mb={1}>
              Text
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={2}>
              <Box flex="1 1 250px">
                <TextField
                  label="Font Color"
                  value={props.color || ''}
                  onChange={(e) => handleChange('color', e.target.value)}
                  fullWidth
                  margin="dense"
                  size="small"
                />
                <HexColorPicker
                  color={props.color || '#000000'}
                  onChange={(color) => handleChange('color', color)}
                  style={{ marginTop: '8px', width: '100%', maxWidth: '250px' }}
                />
              </Box>
              <Box flex="1 1 250px">
                <Typography variant="body2" mb={1}>
                  Text Alignment
                </Typography>
                <Select
                  value={props.textAlign || 'left'}
                  onChange={(e) => handleChange('textAlign', e.target.value)}
                  fullWidth
                  margin="dense"
                  size="small"
                >
                  <MenuItem value="left">Left</MenuItem>
                  <MenuItem value="center">Center</MenuItem>
                  <MenuItem value="right">Right</MenuItem>
                </Select>
              </Box>
            </Box>
          </Box>

          {/* LAYOUT SECTION */}
          <Box
            sx={{
              border: '1px solid #e0e0e0',
              borderRadius: 1,
              p: 2,
            }}
          >
            <Typography variant="subtitle1" fontWeight="bold" mb={1}>
              Layout
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={2}>
              <Box flex="1 1 200px">
                <TextField
                  label="Width"
                  value={props.width || ''}
                  onChange={(e) => handleChange('width', e.target.value)}
                  onBlur={(e) => {
                    const validValue = validateWidth(e.target.value);
                    handleChange('width', validValue);
                  }}
                  fullWidth
                  margin="dense"
                  size="small"
                />
                <TextField
                  label="Height"
                  value={props.height || ''}
                  onChange={(e) => handleChange('height', e.target.value)}
                  onBlur={(e) => {
                    const validValue = validateHeight(e.target.value);
                    handleChange('height', validValue);
                  }}
                  fullWidth
                  margin="dense"
                  size="small"
                />
              </Box>
              <Box flex="1 1 200px">
                <TextField
                  label="Padding"
                  value={props.padding || ''}
                  onChange={(e) => handleChange('padding', e.target.value)}
                  onBlur={(e) => {
                    const validValue = validateSpacing(e.target.value);
                    handleChange('padding', validValue);
                  }}
                  fullWidth
                  margin="dense"
                  size="small"
                />
                <TextField
                  label="Margin"
                  value={props.margin || ''}
                  onChange={(e) => handleChange('margin', e.target.value)}
                  onBlur={(e) => {
                    const validValue = validateSpacing(e.target.value);
                    handleChange('margin', validValue);
                  }}
                  fullWidth
                  margin="dense"
                  size="small"
                />
              </Box>
              <Box flex="1 1 200px">
                <TextField
                  label="Border Radius"
                  value={props.borderRadius || ''}
                  onChange={(e) => handleChange('borderRadius', e.target.value)}
                  onBlur={(e) => {
                    const validValue = validateBorderRadius(e.target.value);
                    handleChange('borderRadius', validValue);
                  }}
                  fullWidth
                  margin="dense"
                  size="small"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {/* File selector modal for background image */}
      <FileSelectorModal
        open={isFileSelectorOpen}
        onClose={() => setIsFileSelectorOpen(false)}
        onSelect={handleFileSelect}
        configParam={configParam}
        handleUpload={handleUpload}
      />
    </>
  );
};

const ComponentEditor: React.FC<{
  type: string;
  props: ComponentProps & ComponentStyleProps;
  onChange: (updatedProps: ComponentProps & ComponentStyleProps) => void;
  onDelete?: () => void;
  configParam: string;
  handleUpload: (blob: Blob, filename: string) => void;
}> = ({
  type,
  props,
  onChange,
  onDelete: handleDelete,
  configParam,
  handleUpload,
}) => {
  const [isFileSelectorOpen, setIsFileSelectorOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null
  );
  const [newText, setNewText] = useState({ type: 'paragraph', contents: '' });
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editingText, setEditingText] = useState<{
    type: string;
    contents: string;
  } | null>(null);
  const [editingImageIndex, setEditingImageIndex] = useState<number | null>(
    null
  );

  // Helper function to get image URL or object
  const getImageUrl = (image: string | ImageObject): string => {
    if (typeof image === 'string') return image;
    return image.url;
  };

  // Helper function to get image alt text
  const getImageAlt = (image: string | ImageObject): string => {
    if (typeof image === 'string') return `Image`;
    return image.altText || `Image`;
  };

  const handleChange = (key: string, value: any) => {
    const updatedProps = { ...props };
    if (value === '' || value === undefined) {
      delete updatedProps[key];
    } else {
      updatedProps[key] = value;
    }
    onChange(updatedProps);
  };

  const handleFileSelect = (fileUrl: string) => {
    if (selectedImageIndex !== null) {
      // Creating a new image object with the file URL
      const newImageObject: ImageObject = {
        url: fileUrl,
        title: `Image ${selectedImageIndex + 1}`,
        altText: `Image ${selectedImageIndex + 1} description`,
      };

      const updatedImages = [...(props.images || [])];
      // Replace the old image (string or object) with the new image object
      updatedImages[selectedImageIndex] = newImageObject;
      handleChange('images', updatedImages);
      setSelectedImageIndex(null);
    } else {
      // For background image, handled in General Options
      handleChange('backgroundImage', {
        url: fileUrl,
        title: 'Background Image',
        altText: 'Background image for this component',
      });
    }
    setIsFileSelectorOpen(false);
  };

  const handleRemoveImage = (index: number) => {
    const updatedImages = [...(props.images || [])];
    updatedImages.splice(index, 1);
    handleChange('images', updatedImages);
  };

  const handleUpdateImageMetadata = (
    index: number,
    field: 'title' | 'altText',
    value: string
  ) => {
    const updatedImages = [...(props.images || [])];
    const image = updatedImages[index];

    // If the image is a string, convert it to an object first
    if (typeof image === 'string') {
      updatedImages[index] = {
        url: image,
        title: field === 'title' ? value : '',
        altText: field === 'altText' ? value : '',
      };
    } else {
      // If it's already an object, update the specific field
      updatedImages[index] = {
        ...image,
        [field]: value,
      };
    }

    handleChange('images', updatedImages);
  };

  // Content options specific to each component type
  let contentOptions;

  switch (type) {
    // Previous content options remain unchanged
    case 'Hero':
    case 'PageHeader':
      contentOptions = (
        <>
          <Typography variant="h6">Content Options</Typography>
          <TextField
            label="Title"
            value={props.title || ''}
            onChange={(e) => handleChange('title', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Subtitle"
            value={props.subtitle || ''}
            onChange={(e) => handleChange('subtitle', e.target.value)}
            fullWidth
            margin="normal"
          />
        </>
      );
      break;

    case 'Carousel':
      contentOptions = (
        <>
          <Typography variant="h6">Content Options</Typography>
          <List sx={{ width: '100%', alignItems: 'left' }}>
            {(props.images || []).map(
              (image: string | ImageObject, index: number) => (
                <ListItem
                  key={index}
                  component="div"
                  sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
                >
                  <Box display="flex" width="100%" alignItems="center">
                    <img
                      src={getImageUrl(image)}
                      alt={getImageAlt(image)}
                      style={{
                        width: '50px',
                        height: '50px',
                        marginRight: '10px',
                        objectFit: 'cover',
                      }}
                    />
                    <ListItemButton
                      onClick={() => {
                        setSelectedImageIndex(index);
                        setIsFileSelectorOpen(true);
                      }}
                    >
                      <ListItemText primary="Change Image" />
                    </ListItemButton>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleRemoveImage(index)}
                    >
                      <DeleteOutline />
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        setEditingImageIndex(
                          editingImageIndex === index ? null : index
                        )
                      }
                      sx={{ ml: 1 }}
                    >
                      {editingImageIndex === index
                        ? 'Hide Details'
                        : 'Edit Details'}
                    </Button>
                  </Box>

                  {editingImageIndex === index && (
                    <Box sx={{ ml: 8, mt: 1, width: '100%' }}>
                      <TextField
                        label="Image Title"
                        value={
                          typeof image === 'object' ? image.title || '' : ''
                        }
                        onChange={(e) =>
                          handleUpdateImageMetadata(
                            index,
                            'title',
                            e.target.value
                          )
                        }
                        fullWidth
                        margin="dense"
                        size="small"
                      />
                      <TextField
                        label="Alt Text"
                        value={
                          typeof image === 'object' ? image.altText || '' : ''
                        }
                        onChange={(e) =>
                          handleUpdateImageMetadata(
                            index,
                            'altText',
                            e.target.value
                          )
                        }
                        fullWidth
                        margin="dense"
                        size="small"
                      />
                    </Box>
                  )}
                </ListItem>
              )
            )}
          </List>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedImageIndex((props.images || []).length);
              setIsFileSelectorOpen(true);
            }}
          >
            <AddCircleOutline />
          </Button>
        </>
      );
      break;

    case 'ImageGallery':
      contentOptions = (
        <>
          <Typography variant="h6">Content Options</Typography>
          <List sx={{ width: '100%', alignItems: 'left' }}>
            {(props.images || []).map(
              (image: string | ImageObject, index: number) => (
                <ListItem
                  key={index}
                  component="div"
                  sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
                >
                  <Box display="flex" width="100%" alignItems="center">
                    <img
                      src={getImageUrl(image)}
                      alt={getImageAlt(image)}
                      style={{
                        width: '50px',
                        height: '50px',
                        marginRight: '10px',
                        objectFit: 'cover',
                      }}
                    />
                    <ListItemButton
                      onClick={() => {
                        setSelectedImageIndex(index);
                        setIsFileSelectorOpen(true);
                      }}
                    >
                      <ListItemText primary="Change Image" />
                    </ListItemButton>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleRemoveImage(index)}
                    >
                      <DeleteOutline />
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        setEditingImageIndex(
                          editingImageIndex === index ? null : index
                        )
                      }
                      sx={{ ml: 1 }}
                    >
                      {editingImageIndex === index
                        ? 'Hide Details'
                        : 'Edit Details'}
                    </Button>
                  </Box>

                  {editingImageIndex === index && (
                    <Box sx={{ ml: 8, mt: 1, width: '100%' }}>
                      <TextField
                        label="Image Title"
                        value={
                          typeof image === 'object' ? image.title || '' : ''
                        }
                        onChange={(e) =>
                          handleUpdateImageMetadata(
                            index,
                            'title',
                            e.target.value
                          )
                        }
                        fullWidth
                        margin="dense"
                        size="small"
                      />
                      <TextField
                        label="Alt Text"
                        value={
                          typeof image === 'object' ? image.altText || '' : ''
                        }
                        onChange={(e) =>
                          handleUpdateImageMetadata(
                            index,
                            'altText',
                            e.target.value
                          )
                        }
                        fullWidth
                        margin="dense"
                        size="small"
                      />
                    </Box>
                  )}
                </ListItem>
              )
            )}
          </List>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedImageIndex((props.images || []).length);
              setIsFileSelectorOpen(true);
            }}
          >
            <AddCircleOutline />
          </Button>
          <TextField
            label="Row Height"
            value={props.rowHeight || ''}
            fullWidth
            margin="normal"
            onBlur={(e) => {
              const value = validateHeight(e.target.value);
              handleChange('rowHeight', value);
            }}
            onChange={(e) => handleChange('rowHeight', e.target.value)}
          />
        </>
      );
      break;

    case 'Ingress':
      contentOptions = (
        <>
          <TextField
            label="Title"
            value={props.title || ''}
            onChange={(e) => handleChange('title', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Paragraph"
            value={props.paragraph || ''}
            onChange={(e) => handleChange('paragraph', e.target.value)}
            fullWidth
            multiline
            margin="normal"
          />
        </>
      );
      break;

    case 'TitleParagraph':
      contentOptions = (
        <>
          <TextField
            label="Title"
            value={props.title || ''}
            onChange={(e) => handleChange('title', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Paragraph"
            value={props.paragraph || ''}
            onChange={(e) => handleChange('paragraph', e.target.value)}
            fullWidth
            multiline
            margin="normal"
          />
        </>
      );
      break;

    case 'TextBox':
      contentOptions = (
        <>
          <Typography variant="h6">Content Options</Typography>
          <List sx={{ width: '100%', alignItems: 'left' }}>
            {(props.texts || []).map(
              (text: { type: string; contents: string }, index: number) => (
                <ListItem key={index} component="div">
                  <ListItemText primary={`${text.type}: ${text.contents}`} />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      const updatedTexts = [...(props.texts || [])];
                      updatedTexts.splice(index, 1);
                      handleChange('texts', updatedTexts);
                    }}
                  >
                    <DeleteOutline />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setEditingIndex(index);
                      setEditingText(text);
                    }}
                  >
                    Edit Text
                  </Button>
                </ListItem>
              )
            )}
          </List>
          {editingIndex !== null && editingText && (
            <>
              <Select
                value={editingText.type}
                onChange={(e) =>
                  setEditingText({
                    ...editingText,
                    type: e.target.value as string,
                  })
                }
                fullWidth
              >
                <MenuItem value="title">Title</MenuItem>
                <MenuItem value="subtitle">Subtitle</MenuItem>
                <MenuItem value="paragraph">Paragraph</MenuItem>
              </Select>
              <TextField
                value={editingText.contents}
                onChange={(e) =>
                  setEditingText({ ...editingText, contents: e.target.value })
                }
                placeholder="Enter text"
                fullWidth
                multiline
                margin="normal"
              />
              <Button
                onClick={() => {
                  const updatedTexts = [...(props.texts || [])];
                  updatedTexts[editingIndex] = editingText;
                  handleChange('texts', updatedTexts);
                  setEditingIndex(null);
                  setEditingText(null);
                }}
                variant="contained"
                color="primary"
              >
                Save Text
              </Button>
              <Button
                onClick={() => {
                  setEditingIndex(null);
                  setEditingText(null);
                }}
                variant="contained"
                color="secondary"
              >
                Cancel
              </Button>
            </>
          )}
          <Select
            value={newText.type}
            onChange={(e) =>
              setNewText({ ...newText, type: e.target.value as string })
            }
            fullWidth
          >
            <MenuItem value="title">Title</MenuItem>
            <MenuItem value="subtitle">Subtitle</MenuItem>
            <MenuItem value="paragraph">Paragraph</MenuItem>
          </Select>
          <TextField
            value={newText.contents}
            onChange={(e) =>
              setNewText({ ...newText, contents: e.target.value })
            }
            placeholder="Enter text"
            fullWidth
            multiline
            margin="normal"
          />
          <Button
            onClick={() => {
              const updatedTexts = [...(props.texts || []), newText];
              handleChange('texts', updatedTexts);
              setNewText({ type: 'paragraph', contents: '' });
            }}
            variant="contained"
            color="primary"
          >
            <AddCircleOutline />
          </Button>
        </>
      );
      break;

    default:
      contentOptions = (
        <Typography>No specific options for this component type</Typography>
      );
  }

  return (
    <Box>
      {/* Content options specific to each component type */}
      {contentOptions}

      {/* File selector modal for all components that need it */}
      <FileSelectorModal
        open={isFileSelectorOpen}
        onClose={() => setIsFileSelectorOpen(false)}
        onSelect={handleFileSelect}
        configParam={configParam}
        handleUpload={handleUpload}
      />

      {/* General options shared across all components */}
      <GeneralOptions
        props={props}
        handleChange={handleChange}
        configParam={configParam}
        handleUpload={handleUpload}
      />

      {/* Delete button if onDelete is provided */}
      {handleDelete && (
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="error"
            onClick={handleDelete}
            startIcon={<DeleteOutline />}
          >
            Delete Component
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ComponentEditor;
