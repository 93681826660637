import React, { useState } from 'react';

import { Box } from '@mui/material';

import { ComponentStyleProps, ImageObject } from '../../pages/types';
import withCommonStyles from '../WithCommoStyles';
import ImageFullscreen from './image-fullscreen';

interface ImageGalleryProps extends ComponentStyleProps {
  images: ImageObject[];
  rowHeight: string | number;
  sx: any;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({
  images,
  rowHeight,
  sx,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <Box
      sx={{
        py: '10px',
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr', // 1 column on mobile
          sm: '1fr 1fr', // 2 columns on small screens
          md: '1fr 1fr 1fr', // 3 columns on medium and larger screens
        },
        gridAutoRows: rowHeight, // Set the height of the rows
        gap: '8px',
      }}
      style={sx}
    >
      {images.map((image, index) => (
        <ImageFullscreen
          firstImage={image}
          images={images}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          key={index}
        >
          <Box
            component="img"
            src={image.url || ''}
            alt={image.altText || `Image ${index + 1}`}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              aspectRatio: '9/9',
              cursor: 'pointer',
            }}
            onClick={() => setCurrentIndex(index)}
          />
        </ImageFullscreen>
      ))}
    </Box>
  );
};

export default withCommonStyles(ImageGallery);
