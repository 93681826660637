import React from 'react';

import { SxProps, Theme } from '@mui/material';

import { ComponentStyleProps } from '../pages/types';

const withCommonStyles = <P extends object>(
  WrappedComponent: React.ComponentType<P & { sx?: SxProps<Theme> }>
): React.FC<P & ComponentStyleProps> => {
  return (props) => {
    const commonStyles: SxProps<Theme> = {};

    (Object.keys(props) as (keyof ComponentStyleProps)[]).forEach((key) => {
      if (props[key] !== undefined) {
        (commonStyles as any)[key] = props[key];
      }
    });

    return <WrappedComponent {...(props as P)} sx={commonStyles} />;
  };
};

export default withCommonStyles;
