import imageCompression from 'browser-image-compression';
import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';

interface FileSelectorModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (fileUrl: string) => void;
  configParam: string;
  handleUpload: (blob: Blob, filename: string) => void;
}

const FileSelectorModal: React.FC<FileSelectorModalProps> = ({
  open,
  onClose,
  onSelect,
  configParam,
  handleUpload,
}) => {
  const [files, setFiles] = useState<string[]>([]);
  const [newFile, setNewFile] = useState<File | null>(null);

  useEffect(() => {
    const fetchFiles = async () => {
      const fetchUrl = `https://upload.anundi.fi/files/${configParam}`;

      try {
        const response = await fetch(fetchUrl, {
          method: 'GET',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setFiles(data); // Directly set the array of URLs

        console.log('Files fetched successfully:', data);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };

    if (open) {
      fetchFiles();
    }
  }, [open, configParam]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setNewFile(event.target.files[0]);
    }
  };

  const compressAndUpload = async () => {
    if (newFile) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(newFile, options);
        const blob = new Blob([compressedFile], { type: compressedFile.type });
        const sanitizedFileName = compressedFile.name.replace(/ /g, '_');
        await handleUpload(blob, sanitizedFileName);
        const fileUrl = `./site_data/${configParam}/${sanitizedFileName}`;
        console.log('File uploaded successfully:', fileUrl);
        onSelect(fileUrl);
        setNewFile(null);
      } catch (error) {
        console.error('Error compressing file:', error);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select or Upload Image</DialogTitle>
      <DialogContent>
        <List>
          {files.map((file, index) => (
            <ListItem key={index} component="div">
              <ListItemButton onClick={() => onSelect(file)}>
                <img
                  src={'/site_data/' + file.split('/').slice(-2).join('/')}
                  alt={`thumbnail-${index}`}
                  style={{ width: '50px', height: '50px', marginRight: '10px' }}
                />
                <ListItemText primary={file.split('/').pop()} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box mt={2}>
          <input type="file" placeholder="file" onChange={handleFileChange} />
          <Button
            variant="contained"
            color="primary"
            onClick={compressAndUpload}
            disabled={!newFile}
          >
            Upload
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileSelectorModal;
