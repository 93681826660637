import { Box, Typography } from '@mui/material';

import { ComponentStyleProps } from '../pages/types';
import withCommonStyles from './WithCommoStyles';

interface TitleParagraphProps extends ComponentStyleProps {
  title: string;
  paragraph: string;
  sx?: any;
}

const TitleParagraph: React.FC<TitleParagraphProps> = ({
  title,
  paragraph,
  sx,
}) => {
  return (
    <Box sx={{ display: 'flex', marginTop: '16px', ...sx }}>
      <Box
        sx={{
          width: '20%', // Set left column width to 30%
          padding: '16px 0 0px 20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'top',
          textAlign: 'left',
        }}
      >
        <Typography variant="h3" component="h2">
          {title}
        </Typography>
      </Box>
      <Box sx={{ flex: 1, padding: '16px' }}>
        <Typography variant="body1" component="p">
          {paragraph}
        </Typography>
      </Box>
    </Box>
  );
};

export default withCommonStyles(TitleParagraph);
